import React from "react";
// reactstrap components
import {
  Button,
  Input,
  Modal,
  Form,
  FormGroup,
} from "reactstrap";


import emailProviders from 'email-providers/common.json';
import isValidDomain from 'is-valid-domain';

import { api } from '../../../services';

class Modals extends React.PureComponent {
  lastState = false;

  static getDerivedStateFromProps = (nextProps, prevState) => {
    let nextState = prevState;

    if (nextProps.show && !this.lastState) {
      const newState = { ...prevState };
      if (nextProps.content) {
        nextState =  {
          ...newState,
          datas: {
            domain: nextProps.content.domain,
          },
          error: {
            domain: false,
          }
        };
      } else {
        nextState =  {
          ...newState,
          datas: {
            domain: '',
          },
          error: {
            domain: false,
          }
        };
      }
    }

    this.lastState = nextProps.show;
    return nextState;
  }

  state = {
    datas: {
      domain: '',
    },
    error: {
      domain: false,
    }
  }

  doValidation = () => {
    const { datas } = this.state;
    const error = {
      ...this.state.error,
    };
    let hasError = false;

    const domainName = (datas.domain || '').toLowerCase().trim();

    if (!domainName) {
      error.domain = true;
      hasError = true;
    }

    const isEmailProvider = emailProviders.indexOf(domainName) !== -1;

    if (isEmailProvider) {
      error.domain = 'Please enter a business domain name';
      hasError = true;
    }

    if (!isValidDomain(domainName)) {
      error.domain = 'Invalid domain name';
      hasError = true;
    }

    this.setState({
      error,
    });

    return !hasError;
  }

  setFormValue = (field, e) => {
    const { target } = e;
    this.setState({
      datas: {
        [field]: target.value,
      }
    });
  }

  doSave = async (e) => {
    const { onClose, content } = this.props;
    const { datas } = this.state;
    e.preventDefault();

    const domainName = (datas.domain || '').toLowerCase().trim();

    if (!this.doValidation()) {
      return;
    }

    try {
      if (content && content.id) {
        await api.updateDomain(content.id, domainName);
      } else {
        await api.addDomain(domainName);
      }
    } catch (e) {
      const { data } = e.response;
      if (data.error && data.type === 'DUPLICATE') {
        this.setState({
          error: {
            domain: 'This domain name is already registered.'
          }
        });
        return;
      }
      console.log(e.response);
      return;
    }

    onClose && onClose(true);
  }

  render() {
    const { show, onClose, content } = this.props;
    const { error, datas } = this.state;

    return (
      <Modal
        isOpen={show}
        toggle={onClose}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            {content ? 'Edit domain' : 'Create a domain'}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Form className="needs-validation" noValidate>
          <div className="modal-body">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="clientName"
                >
                  Domain name
                </label>
                <Input
                  placeholder="Domain name"
                  type="text"
                  defaultValue={datas.domain}
                  onChange={e => this.setFormValue('domain', e)}
                  invalid={!!error.domain}
                  required
                />
                {error.domain && typeof error.domain === 'string' ? (
                  <div className="invalid-feedback">
                    {error.domain}
                  </div>
                ) : null}
              </FormGroup>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              onClick={this.doSave}
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

Modals.defaultProps = {
  show: false,
  content: null,
};

export default Modals;