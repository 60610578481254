import React from "react";
// reactstrap components
import {
  Button,
  Input,
  Modal,
} from "reactstrap";

import { api } from '../../../services';

class Modals extends React.Component {
  state = {
    name: '',
  }

  doSave = async () => {
    const { onClose, content } = this.props;
    const { name } = this.state;
    
    const teamName = (name || '').trim();

    if (!teamName) {
      return onClose && onClose();
    }

    if (content && content.id) {
      await api.updateTeam(content.id, name);
    } else {
      await api.addTeam(name);
    }

    onClose && onClose(true);
  }

  
  render() {
    const { show, onClose, content } = this.props;

    return (
      <Modal
        isOpen={show}
        toggle={onClose}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            {content ? 'Edit team' : 'Create a team'}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Input
            placeholder="Team name"
            type="text"
            defaultValue={content && content.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={this.doSave}
          >
            Save
          </Button>
        </div>
      </Modal>
    );
  }
}

Modals.defaultProps = {
  show: false,
  content: null,
};

export default Modals;