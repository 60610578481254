/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import moment from "moment";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Progress
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import NewClientModal from './NewClientModal';

import { api } from '../../../services';


class Clients extends React.Component {
  state = {
    showCreateModal: false,
    modalContent: null,
    clients: null
  }

  fetchingApi = false;

  componentDidMount() {
    this.fetchDatas();
  }

  componentDidUpdate = () => {
    this.fetchDatas();
  }

  fetchDatas = async (forceRefresh) => {
    const { isInitializing } = this.props;
    const { clients } = this.state;

    if (isInitializing || (clients && !forceRefresh) || this.fetchingApi) {
      return;
    }
  
    this.fetchingApi = true;
    try {
      const datas = await api.getClients();
      this.setState({
        clients: datas,
      });

    } catch (e) {}

    this.fetchingApi = false;
  }

  closeModal = (saved) => {
    if (saved) {
      this.fetchDatas(true);
    }

    this.setState({
      showCreateModal: false,
    });
  }

  showCreate = (e) => {
    e.preventDefault();
    this.setState({ showCreateModal: true, modalContent: null });
  }

  showUpdate = (content) => {
    this.setState({ showCreateModal: true, modalContent: content });
  }

  changeStatus = async (client, active) => {
    if (active) {
      await api.activeClient(client.id);
    } else {
      await api.deactiveClient(client.id);
    }
  }

  impersonateClient = async (client) => {
    await api.impersonate(client.id);
  }

  render() {
    const { clients } = this.state;
    const { user } = api;

    return (
      <>
        <NewClientModal
          show={this.state.showCreateModal}
          content={this.state.modalContent}
          onClose={this.closeModal}
        />
        <SimpleHeader name="Teams" />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Clients</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    href="#pablo"
                    id={`addClient`}
                    onClick={this.showCreate}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-fat-add" />
                    </span>
                    <span>Add client</span>
                  </Button>
                  <UncontrolledTooltip delay={0} target={`addClient`}>
                    Add a new client
                  </UncontrolledTooltip>
                </Col>
              </Row>
            </CardHeader>

            <Table
              className="align-items-center table-flush"
              responsive
              striped
            >
              <thead className="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Created at</th>
                  <th>Users</th>
                  <th>Licenses</th>
                  <th>Subdomain</th>
                  <th>Active</th>
                  <th className="fit" />
                </tr>
              </thead>
              <tbody>
                {clients && clients.map(client => (
                  <tr key={client.id}>
                    <td className="table-user">
                      <b>{client.name}</b>
                    </td>
                    <td>
                      <span className="text-muted">{moment(client.created_at).format('LL')}</span>
                    </td>
                    <td>
                      {client.users} / {client.licenses}
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{Math.round(client.users * 100 / client.licenses)}%</span>
                        <div>
                          <Progress
                            max="100"
                            value={Math.round(client.users * 100 / client.licenses)}
                            color="gradient-info"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <a
                        href={`https://${client.subdomain}.app.mybodyecology.com`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {client.subdomain}
                      </a>
                    </td>
                    <td>
                      <label className="custom-toggle">
                        <input
                          defaultChecked={client.active}
                          type="checkbox"
                          onChange={(e) => {
                            this.changeStatus(client, e.target.checked);
                          }}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="No"
                          data-label-on="Yes"
                        />
                      </label>
                    </td>
                    <td className="table-actions fit align-right">
                      {client.notes ? (
                        <>
                          <a
                            className="table-action"
                            href={`#${client.id}`}
                            id={`clientNote-${client.id}`}
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-info-circle"></i>
                          </a>
                          <UncontrolledTooltip delay={0} target={`clientNote-${client.id}`}>
                            {client.notes}
                          </UncontrolledTooltip>
                        </>
                      ) : null}
                      {user && user.client && user.client.id !== client.id ? (
                        <>
                          <a
                            className="table-action"
                            href={`#${client.id}`}
                            id={`clientGod-${client.id}`}
                            onClick={(e) => {
                              e.preventDefault();
                              this.impersonateClient(client);
                            }}
                          >
                            <i className="fas fa-bible" />
                          </a>
                          <UncontrolledTooltip delay={0} target={`clientGod-${client.id}`}>
                            Impersonate client
                          </UncontrolledTooltip>
                        </>
                      ) : null}
                      
                      <a
                        className="table-action"
                        href={`#${client.id}`}
                        id={`clientEdit-${client.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.showUpdate(client);
                        }}
                      >
                        <i className="fas fa-edit" />
                      </a>
                      <UncontrolledTooltip delay={0} target={`clientEdit-${client.id}`}>
                        Edit client
                      </UncontrolledTooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Container>
      </>
    );
  }
}

export default Clients;
