/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import firebase from 'firebase/app';

import routes from "routes.js";

class Admin extends React.PureComponent {
  state = {
    sidenavOpen: true,
    isLoggedIn: false,
    isInitializing: true,
    user: null,
  };
  unmount = false;
  mainContent;
  firebaseUnsubcribe;

  constructor(props) {
    super(props);

    if (firebase.auth().currentUser) {
      this.state.isInitializing = false;
      this.state.isLoggedIn = true;
      this.state.user = firebase.auth().currentUser;
      console.log('Init layout', this.state);
    }
  }

  componentDidMount = () => {
    this.firebaseUnsubcribe = firebase.auth().onIdTokenChanged(async (currentUser) => {
      if (this.unmount) {
        return;
      }

      console.log('[Admin Layout] auth changed', currentUser);
      let user;

      if (currentUser) {
        const infos = await firebase.auth().currentUser.getIdTokenResult();
        user = infos.claims;
      }

      this.setState({
        isInitializing: false,
        isLoggedIn: !!user,
        user,
      });
    });
  }

  componentWillUnmount = () => {
    this.unmount = true;
    this.firebaseUnsubcribe();
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname && this.mainContent) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainContent.scrollTop = 0;
    }
  }
  getRoutes = routes => {
    const { isInitializing } = this.state;

    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={() => <prop.component key={key} isInitializing={isInitializing} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = e => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen
    });
  };
  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      "admin/alternative-dashboard"
    ) === -1
      ? "dark"
      : "light";
  };

  renderDashboard = () => {

  }

  render = () => {
    const { isLoggedIn, isInitializing, user } = this.state;

    if (!isLoggedIn && !isInitializing) {
      return <Redirect to="/auth" />;
    }

    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          isMaster={user && user.isMaster}
          logo={{
            innerLink: "/",
            imgSrc: require("assets/img/brand/logo.png"),
            imgAlt: "..."
          }}
        />
        <div
          className="main-content"
          ref={(ref) => this.mainContent = ref}
          onClick={this.closeSidenav}
        >
          <AdminNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
            user={this.state.user}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <AdminFooter />
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

export default Admin;
