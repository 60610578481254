import React from "react";
// reactstrap components
import {
  Button,
  Input,
  Modal,
  Form,
  FormGroup,
} from "reactstrap";

import { api } from '../../../services';

class Modals extends React.Component {
  lastState = false;

  static getDerivedStateFromProps = (nextProps, prevState) => {
    let nextState = prevState;

    if (nextProps.show && !this.lastState) {
      const newState = { ...prevState };
      if (nextProps.content) {
        nextState =  {
          ...newState,
          datas: {
            name: nextProps.content.name,
            email: nextProps.content.email
          },
          error: {
            name: false,
            email: false,
          }
        };
      } else {
        nextState =  {
          ...newState,
          datas: {
            name: '',
            email: '',
          },
          error: {
            domain: false,
            email: false,
          }
        };
      }
    }

    this.lastState = nextProps.show;
    return nextState;
  }

  state = {
    datas: {
      name: '',
      email: '',
    },
    error: {
      name: false,
      email: false,
    }
  }

  doValidation = () => {
    const { content } = this.props;
    const { datas } = this.state;
    const error = {
      ...this.state.error,
    };
    let hasError = false;


    if (!`${datas.name}`.trim()) {
      error.name = true;
      hasError = true;
    }

    if (content && !`${datas.email}`.trim()) {
      error.email = true;
      hasError = true;
    }

    this.setState({
      error,
    });

    return !hasError;
  }

  setFormValue = (field, e) => {
    const { target } = e;
    this.setState({
      datas: {
        ...this.state.datas,
        [field]: target.value,
      }
    });
  }

  doSave = async (e) => {
    const { onClose, content } = this.props;
    const { datas } = this.state;
    e.preventDefault();

    if (!this.doValidation()) {
      return;
    }

    try {
      if (content && content.id) {
        await api.updateMember(content.id, datas.name);
      } else {
        await api.addMember(datas.name, datas.email);
      }
    } catch(e) {
      const { data } = e.response || { data: {} };
      if (data.error && data.type === 'INVALID_EMAIL') {
        this.setState({
          error: {
            email: 'Please enter a valid email address'
          }
        });
        return;
      }
      console.log(e.response);
      return;
    }

    onClose && onClose(true);
  }

  render() {
    const { show, onClose, content } = this.props;
    const { error } = this.state;

    return (
      <Modal
        isOpen={show}
        toggle={onClose}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            {content ? 'Edit member' : 'Create a member'}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Form className="needs-validation" noValidate>
          <div className="modal-body">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="memberName"
              >
                Full name
              </label>
              <Input
                id="memberName"
                placeholder="Name"
                type="text"
                defaultValue={content && content.name}
                onChange={e => this.setFormValue('name', e)}
                invalid={!!error.name}
                required
              />
              {error.name && typeof error.name === 'string' ? (
                <div className="invalid-feedback">
                  {error.name}
                </div>
              ) : null}
            </FormGroup>
            {!content ? (
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="memberEmail"
                >
                  Email
                </label>
                <Input
                  id="memberEmail"
                  placeholder="Email"
                  type="email"
                  defaultValue={content && content.email}
                  onChange={e => this.setFormValue('email', e)}
                  invalid={!!error.email}
                  required
                />
                {error.email && typeof error.email === 'string' ? (
                  <div className="invalid-feedback">
                    {error.email}
                  </div>
                ) : null}
              </FormGroup>
            ) : null}
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              onClick={this.doSave}
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

Modals.defaultProps = {
  show: false,
  content: null,
};

export default Modals;