import axios from "axios";
import firebase from 'firebase/app';

const {
  REACT_APP_API_URL
} = process.env;

class API {
  access_token;
  user;
  isLogged;
  expiresAt = new Date();

  initialize = () => {
    const callback = async (currentUser) => {
      console.log('[API] auth changed', currentUser);

      if (!currentUser) {
        this.isLogged = false;
        this.user = '';
        return;
      }

      this.setToken();
    }
    firebase.auth().onIdTokenChanged(callback);
  }

  setToken = async (forceRefresh = false) => {
    const infos = await firebase.auth().currentUser.getIdTokenResult(forceRefresh);
    this.access_token = infos.token;
    this.expiresAt = new Date(infos.expirationTime);
    this.user = infos.claims;
    this.isLogged = true;

    axios.defaults.headers.common['Authorization'] = `Bearer ${this.access_token}`;
  }

  checkToken = async () => {
    if (!this.isLogged) {
      return;
    }

    if (this.expiresAt < new Date()) {
      console.log('Token expired', this.expiresAt);
      await this.setToken(true);
    }

  }

  login = async (token) => {
    await this.checkToken();
    const resp = await axios.post(`${REACT_APP_API_URL}/admin/auth/login`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    await firebase.auth().signInWithCustomToken(resp.data.token);
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    await this.setToken();
  }

  impersonate = async (clientId) => {
    await this.checkToken();
    const resp = await axios.post(`${REACT_APP_API_URL}/admin/auth/impersonate`, {
      clientId
    });

    await firebase.auth().signInWithCustomToken(resp.data.token);
    await this.setToken();
    return resp.data;
  }

  getStatistics = async () => {
    await this.checkToken();
    const resp = await axios.get(`${REACT_APP_API_URL}/admin/statistics`);
    return resp.data;
  }

  getTeams = async () => {
    await this.checkToken();
    const resp = await axios.get(`${REACT_APP_API_URL}/admin/teams`);
    return resp.data;
  }

  getDomains = async () => {
    await this.checkToken();
    const resp = await axios.get(`${REACT_APP_API_URL}/admin/domains`);
    return resp.data;
  }

  getMembers = async () => {
    await this.checkToken();
    const resp = await axios.get(`${REACT_APP_API_URL}/admin/members`);
    return resp.data;
  }

  getClients = async () => {
    await this.checkToken();
    const resp = await axios.get(`${REACT_APP_API_URL}/admin/clients`);
    return resp.data;
  }

  addMember = async (name, email) => {
    await this.checkToken();
    const resp = await axios.post(`${REACT_APP_API_URL}/admin/members`, {
      name,
      email
    });
    return resp.data;
  }

  updateMember = async (id, name) => {
    await this.checkToken();
    const resp = await axios.post(`${REACT_APP_API_URL}/admin/members/${id}`, {
      name,
    });
    return resp.data;
  }

  deleteMember = async (id) => {
    await this.checkToken();
    const resp = await axios.delete(`${REACT_APP_API_URL}/admin/members/${id}`);
    return resp.data;
  }

  addTeam = async (name) => {
    await this.checkToken();
    const resp = await axios.post(`${REACT_APP_API_URL}/admin/teams`, {
      name
    });
    return resp.data;
  }

  updateTeam = async (id, name) => {
    await this.checkToken();
    const resp = await axios.post(`${REACT_APP_API_URL}/admin/teams/${id}`, {
      name
    });
    return resp.data;
  }

  deleteTeam = async (id) => {
    await this.checkToken();
    const resp = await axios.delete(`${REACT_APP_API_URL}/admin/teams/${id}`);
    return resp.data;
  }

  addTeam = async (name) => {
    await this.checkToken();
    const resp = await axios.post(`${REACT_APP_API_URL}/admin/teams`, {
      name
    });
    return resp.data;
  }

  updateTeam = async (id, name) => {
    await this.checkToken();
    const resp = await axios.post(`${REACT_APP_API_URL}/admin/teams/${id}`, {
      name
    });
    return resp.data;
  }

  deleteTeam = async (id) => {
    await this.checkToken();
    const resp = await axios.delete(`${REACT_APP_API_URL}/admin/teams/${id}`);
    return resp.data;
  }

  addDomain = async (domain) => {
    await this.checkToken();
    const resp = await axios.post(`${REACT_APP_API_URL}/admin/domains`, {
      domain
    });
    return resp.data;
  }

  updateDomain = async (id, domain) => {
    await this.checkToken();
    const resp = await axios.post(`${REACT_APP_API_URL}/admin/domains/${id}`, {
      domain
    });
    return resp.data;
  }

  deleteDomain = async (id) => {
    await this.checkToken();
    const resp = await axios.delete(`${REACT_APP_API_URL}/admin/domains/${id}`);
    return resp.data;
  }

  addClient = async (name, subdomain, licenses, notes) => {
    await this.checkToken();
    const resp = await axios.post(`${REACT_APP_API_URL}/admin/clients`, {
      name,
      licenses,
      subdomain,
      notes,
    });
    return resp.data;
  }

  updateClient = async (id, name, subdomain, licenses, notes) => {
    await this.checkToken();
    const resp = await axios.post(`${REACT_APP_API_URL}/admin/clients/${id}`, {
      name,
      licenses,
      subdomain,
      notes
    });
    return resp.data;
  }

  activeClient = async (id) => {
    await this.checkToken();
    const resp = await axios.post(`${REACT_APP_API_URL}/admin/clients/${id}/active`);
    return resp.data;
  }

  deactiveClient = async (id) => {
    await this.checkToken();
    const resp = await axios.post(`${REACT_APP_API_URL}/admin/clients/${id}/deactive`);
    return resp.data;
  }
} 

export const api = new API();
