import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

import { withRouter } from 'react-router-dom';

import firebase from 'firebase/app';

import { api } from '../../../services';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";

class Login extends React.Component {
  state = {
    email: null,
    password: null,
    authError: null,
  };
  
  signupWithGoogle = async () => {
    const { history } = this.props;
    await firebase.auth().signOut();
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    try {
      await firebase.auth().signInWithPopup(googleAuthProvider);
    } catch (e) {
      console.error('Auth error', e);
      return;
    }
    await this.authenticateApi();
    history.push('/admin/dashboard');
  }

  authenticateApi = async () => {
    let token = await firebase.auth().currentUser.getIdToken();

    if (!token) {
      return;
    }
    await api.login(token);
  }

  loginWithCredentials = async () => {
    const { history } = this.props;
    const { email, password } = this.state;

    try {
      await firebase.auth().signInWithEmailAndPassword(email || '', password || '');
    } catch (e) {
      this.setState({
        authError: e.message
      });

      return;
    }

    await this.authenticateApi();
    history.push('/admin');
  }

  resetPassword = async () => {
    const { history } = this.props;

    history.push('/auth/recover');
  }
 
  renderForm = () => {
    const { authError } = this.state;

    return (
      <>
        <CardHeader className="bg-transparent pb-5">
          <div className="text-muted text-center mt-2 mb-3">
            <small>Sign in with</small>
          </div>
          <div className="btn-wrapper text-center">
            <Button
              className="btn-neutral btn-icon"
              color="default"
              href="#pablo"
              onClick={this.signupWithGoogle}
            >
              <span className="btn-inner--icon mr-1">
                <img
                  alt="..."
                  src={require("assets/img/icons/common/google.svg")}
                />
              </span>
              <span className="btn-inner--text">Google</span>
            </Button>
          </div>
        </CardHeader>
        <CardHeader className="bg-transparent px-lg-3 py-lg-3">
          <div className="text-center text-muted mb-2">
            <small>First visit ?</small>
          </div>
          <div className="btn-wrapper text-center">
            <Button
              className="my-4"
              color="info"
              type="button"
              onClick={this.resetPassword}
            >
              Active your account
            </Button>
          </div>
        </CardHeader>
        <CardBody className="px-lg-5 py-lg-3">
          <div className="text-center text-muted mb-4">
            <small>Or sign in with credentials</small>
          </div>
          <Form role="form">
            <FormGroup
              className={classnames("mb-3", {
                focused: this.state.focusedEmail
              })}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email"
                  type="email"
                  onChange={(e) => this.setState({ email: e.target.value })}
                  onFocus={() => this.setState({ focusedEmail: true })}
                  onBlur={() => this.setState({ focusedEmail: false })}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup
              className={classnames({
                focused: this.state.focusedPassword
              })}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  type="password"
                  onChange={(e) => this.setState({ password: e.target.value })}
                  onFocus={() =>
                    this.setState({ focusedPassword: true })
                  }
                  onBlur={() =>
                    this.setState({ focusedPassword: false })
                  }
                />
              </InputGroup>
            </FormGroup>
            {authError ? (
              <div className="text-center text-red mb-4">
                <small>{authError}</small>
              </div>
            ) : null}
            <div className="text-center">
              <Button
                className="my-4"
                color="info"
                type="button"
                onClick={this.loginWithCredentials}
              >
                Sign in
              </Button>
            </div>
          </Form>
        </CardBody>
      </>
    );
  }

  render() {
    return (
      <>
        <AuthHeader
          title="Welcome!"
          lead="Please use the form below to access your private dashboard."
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                {this.renderForm()}
                </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-light"
                    href="#pablo"
                    onClick={this.resetPassword}
                  >
                    <small>Forgot password?</small>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(Login);
