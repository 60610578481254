import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

import { withRouter } from 'react-router-dom';

import firebase from 'firebase/app';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";

class Recover extends React.Component {
  state = {
    email: null,
    authError: null,
    authSuccess: null,
  };
  

  backToLogin = () => {
    const { history } = this.props;

    history.push('/auth/login');
  }

  resetPassword = async (e) => {
    e.preventDefault();
  
    const { email } = this.state;
    this.setState({
      authError: null,
      authSuccess: null,
    });

    try {
      await firebase.auth().sendPasswordResetEmail(email);
    } catch (e) {
      this.setState({
        authError: e.message,
        authSuccess: null,
      })
    }

    this.setState({
      authSuccess: 'We sent you an email, please check your inbox',
    });
  }
 
  renderForm = () => {
    const { authError, authSuccess } = this.state;

    return (
      <>
        <CardHeader className="bg-transparent pb-2">
          <div className="text-muted text-center mt-2 mb-3">
            Enter your email address below and we'll send you a link to reset your password.
          </div>
        </CardHeader>
        <CardBody className="px-lg-5 py-lg-5">
          <Form role="form">
            <FormGroup
              className={classnames("mb-3", {
                focused: this.state.focusedEmail
              })}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email"
                  type="email"
                  onChange={(e) => this.setState({ email: e.target.value })}
                  onFocus={() => this.setState({ focusedEmail: true })}
                  onBlur={() => this.setState({ focusedEmail: false })}
                />
              </InputGroup>
            </FormGroup>
            {authError ? (
              <div className="text-center text-red mb-4">
                <small>{authError}</small>
              </div>
            ) : null}
            {authSuccess ? (
              <div className="text-center text-green mb-4">
                <small>{authSuccess}</small>
              </div>
            ) : null}
            <div className="text-center">
              <Button
                className="my-4"
                color="info"
                type="submit"
                onClick={this.resetPassword}
              >
                Send email
              </Button>
            </div>
          </Form>
        </CardBody>
      </>
    );
  }

  render() {
    return (
      <>
        <AuthHeader
          title="Reset your password"
          lead="Don't worry, we will send you a mail to reset your password"
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                {this.renderForm()}
                </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-light"
                    href="#pablo"
                    onClick={this.backToLogin}
                  >
                    <small>Back to login</small>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(Recover);
