import React from "react";
// reactstrap components
import {
  Button,
  Input,
  Form,
  FormGroup,
  Modal,
} from "reactstrap";

import { api } from '../../../services';

class Modals extends React.Component {
  slastState = false;

  static getDerivedStateFromProps = (nextProps, prevState) => {
    let nextState = prevState;

    if (nextProps.show && !this.lastState) {
      const newState = { ...prevState };
      if (nextProps.content) {
        nextState =  {
          ...newState,
          datas: {
            ...nextProps.content,
          },
          error: {
            name: false,
          }
        };
      } else {
        nextState =  {
          ...newState,
          datas: {
            name: '',
            subdomain: '',
            licenses: 0,
            notes: '',
          },
          error: {
            name: false,
          }
        };
      }
    }

    this.lastState = nextProps.show;
    return nextState;
  }

  state = {
    datas: {
      name: '',
      subdomain: '',
      licenses: 0,
      notes: '',
    },
    error: {
      name: false,
    }
  }

  doValidation = () => {
    const { datas } = this.state;
    const error = {
      ...this.state.error,
    };
    let hasError = false;


    if (!`${datas.name}`.trim()) {
      error.name = true;
      hasError = true;
    }

    this.setState({
      error,
    });

    return !hasError;
  }

  setFormValue = (field, e) => {
    const { target } = e;
    this.setState({
      datas: {
        ...this.state.datas,
        [field]: target.value,
      }
    });
  }

  doSave = async (e) => {
    const { onClose, content } = this.props;
    const { datas } = this.state;
    e.preventDefault();

    if (!this.doValidation()) {
      return;
    }

    try {
      if (content && content.id) {
        await api.updateClient(content.id, datas.name, datas.subdomain, datas.licenses, datas.notes);
      } else {
        await api.addClient(datas.name, datas.subdomain, datas.licenses, datas.notes);
      }
    } catch(e) {
      const { data } = e.response || { data: {} };
      if (data.error && data.type === 'DUPLICATE') {
        this.setState({
          error: {
            name: 'Client name or subdomain already exists'
          }
        });
        return;
      }
      
      return;
    }

    onClose && onClose(true);
  }

  render() {
    const { show, onClose, content } = this.props;
    const { error } = this.state;

    return (
      <Modal
        isOpen={show}
        toggle={onClose}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            {content ? 'Edit client' : 'Create a client'}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="needs-validation" noValidate>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="clientName"
              >
                Name
              </label>
              <Input
                id="clientName"
                type="text"
                defaultValue={content && content.name}
                onChange={e => this.setFormValue('name', e)}
                invalid={error.name}
                required
              />
              {error.name && typeof error.name === 'string' ? (
                <div className="invalid-feedback">
                  {error.name}
                </div>
              ) : null}
            </FormGroup>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="clientSubdomain"
              >
                Subdomain
              </label>
              <Input
                id="clientName"
                type="text"
                defaultValue={content && content.subdomain}
                onChange={e => this.setFormValue('subdomain', e)}
                invalid={this.state.error.subdomain}
                required
              />
            </FormGroup>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="licenses"
              >
                Licenses
              </label>
              <Input
                id="licenses"
                type="number"
                placeholder={0}
                defaultValue={(content && content.licenses) || 0}
                onChange={e => this.setFormValue('licenses', e)}
              />
            </FormGroup>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="notes"
              >
                Notes
              </label>
              <Input
                id="notes"
                type="textarea"
                rows={4}
                defaultValue={content && content.notes}
                onChange={e => this.setFormValue('notes', e)}
              />
            </FormGroup>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
            onClick={this.doSave}
          >
            Save
          </Button>
        </div>
      </Modal>
    );
  }
}

Modals.defaultProps = {
  show: false,
  content: null,
};

export default Modals;