/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import moment from "moment";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

import NewTeamModal from './NewTeamModal';
import { api } from '../../../services';

class Teams extends React.Component {
  state = {
    showCreateModal: false,
    modalContent: null,
    teams: null,
  }

  fetchingApi = false;

  componentDidMount() {
    this.fetchDatas();
  }

  componentDidUpdate = () => {
    this.fetchDatas();
  }

  closeModal = (saved) => {

    if (saved) {
      this.fetchDatas(true);
    }

    this.setState({
      showCreateModal: false,
    });
  }

  showCreate = (e) => {
    e.preventDefault();
    this.setState({ showCreateModal: true, modalContent: null });
  }

  showUpdate = (content) => {
    this.setState({ showCreateModal: true, modalContent: content });
  }

  deleteTeam = async (team) => {
    await api.deleteTeam(team.id);
    this.fetchDatas(true);
  }

  fetchDatas = async (forceRefresh) => {
    const { isInitializing } = this.props;
    const { teams } = this.state;

    if (isInitializing || (teams && !forceRefresh) || this.fetchingApi) {
      return;
    }
  
    this.fetchingApi = true;
    try {
      const datas = await api.getTeams();
      this.setState({
        teams: datas,
      });

    } catch (e) {}

    this.fetchingApi = false;
  }

  render() {
    const { teams } = this.state;

    return (
      <>
        <SimpleHeader name="Teams" />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Teams</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    href="#"
                    id="tooltip443412080"
                    onClick={this.showCreate}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-fat-add" />
                    </span>
                    <span>Add team</span>
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip443412080">
                    Add a new team
                  </UncontrolledTooltip>
                </Col>
              </Row>
            </CardHeader>

            <NewTeamModal
              show={this.state.showCreateModal}
              content={this.state.modalContent}
              onClose={this.closeModal}
            />

            <Table
              className="align-items-center table-flush"
              responsive
              striped
            >
              <thead className="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Created at</th>
                  <th>Users</th>
                  <th className="fit" />
                </tr>
              </thead>
              <tbody>
                {teams && teams.map(team => (
                  <tr key={team.id}>
                    <td className="table-user">
                      <b>{team.name}</b>
                    </td>
                    <td>
                      <span className="text-muted">{moment(team.date).format('LL')}</span>
                    </td>
                    <td>
                      {team.users}
                    </td>
                    <td className="table-actions fit">
                      <a
                        className="table-action"
                        href="#edit"
                        id={`editTeam${team.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.showUpdate(team);
                        }}
                      >
                        <i className="fas fa-edit" />
                      </a>
                      <UncontrolledTooltip delay={0} target={`editTeam${team.id}`}>
                        Edit team
                      </UncontrolledTooltip>
                      {team.users <= 0 ? (
                        <>
                          <a
                            className="table-action table-action-delete"
                            href="#pablo"
                            id={`deleteTeam${team.id}`}
                            onClick={(e) => {
                              e.preventDefault();
                              this.deleteTeam(team);
                            }}
                          >
                            <i className="fas fa-trash" />
                          </a>
                          <UncontrolledTooltip delay={0} target={`deleteTeam${team.id}`}>
                            Delete team
                          </UncontrolledTooltip>
                        </>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Container>
      </>
    );
  }
}

export default Teams;
