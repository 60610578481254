/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import moment from "moment";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import NewDomainModal from './NewDomainModal';

import { api } from '../../../services';


class Domains extends React.Component {
  state = {
    showCreateModal: false,
    modalContent: null,
    domains: null,
  }

  fetchingApi = false;

  componentDidMount() {
    this.fetchDatas();
  }

  componentDidUpdate = () => {
    this.fetchDatas();
  }

  closeModal = (saved) => {
    if (saved) {
      this.fetchDatas(true);
    }

    this.setState({
      showCreateModal: false,
    });
  }

  showCreate = (e) => {
    e.preventDefault();
    this.setState({ showCreateModal: true, modalContent: null });
  }

  showUpdate = (content) => {
    this.setState({ showCreateModal: true, modalContent: content });
  }

  fetchDatas = async (forceRefresh) => {
    const { isInitializing } = this.props;
    const { domains } = this.state;

    if (isInitializing || (domains && !forceRefresh) || this.fetchingApi) {
      return;
    }
  
    this.fetchingApi = true;
    try {
      const datas = await api.getDomains();
      this.setState({
        domains: datas,
      });

    } catch (e) {}

    this.fetchingApi = false;
  }

  doDelete = async (domain) => {
    await api.deleteDomain(domain.id);

    this.fetchDatas(true);
  }

  render() {
    const { domains } = this.state;
    return (
      <>
        <NewDomainModal
          show={this.state.showCreateModal}
          content={this.state.modalContent}
          onClose={this.closeModal}
        />
        <SimpleHeader name="Domains" />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Domains</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    href="#pablo"
                    id={`addDomain`}
                    onClick={this.showCreate}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-fat-add" />
                    </span>
                    <span>Add domain</span>
                  </Button>
                  <UncontrolledTooltip delay={0} target={`addDomain`}>
                    Add a new domain
                  </UncontrolledTooltip>
                </Col>
              </Row>
            </CardHeader>

            <Table
              className="align-items-center table-flush"
              responsive
              striped
            >
              <thead className="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Created at</th>
                  <th className="fit" />
                </tr>
              </thead>
              <tbody>
                {domains && domains.map(domain => (
                  <tr key={domain.id}>
                    <td className="table-user">
                      <b>{domain.domain}</b>
                    </td>
                    <td>
                      <span className="text-muted">{moment(domain.created_at).format('LL')}</span>
                    </td>
                    <td className="table-actions fit">
                      <a
                        className="table-action"
                        href="#pablo"
                        id={`updateDomain${domain.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.showUpdate(domain);
                        }}
                      >
                        <i className="fas fa-edit" />
                      </a>
                      <UncontrolledTooltip delay={0} target={`updateDomain${domain.id}`}>
                        Edit domain
                      </UncontrolledTooltip>
                      <a
                        className="table-action table-action-delete"
                        href="#pablo"
                        id={`deleteDomain${domain.id}`}
                        onClick={e => {
                          e.preventDefault();
                          this.doDelete(domain);
                        }}
                      >
                        <i className="fas fa-trash" />
                      </a>
                      <UncontrolledTooltip delay={0} target={`deleteDomain${domain.id}`}>
                        Delete domain
                      </UncontrolledTooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Container>
      </>
    );
  }
}

export default Domains;
