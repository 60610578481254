/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import Chart from "chart.js";

import List from "list.js";

// react plugin used to create charts
import { Line } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import { api } from '../../../services';

// core components
import CardsHeader from "components/Headers/CardsHeader.jsx";

import {
  chartOptions,
  parseOptions,
  dashboardGraphOptions,
} from "variables/charts.jsx";

class Dashboard extends React.PureComponent {
  state = {
    stats: null,
  };

  unmount = false;
  fetchingStats = false;
  lastReindex = Date.now();

  teamsSort;
  chaptersSort;

  componentDidMount() {
    this.fetchStats();
    this.teamsSort = new List(this.refs["teams-list"], {
      valueNames: ["name", "users", "completion", "quizz-success"],
      listClass: "list"
    });
    this.chaptersSort = new List(this.refs["chapters-list"], {
      valueNames: ["name", "users", "completion", "quizz-success"],
      listClass: "list"
    });
  }

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  componentDidUpdate = () => {
    const { stats } = this.state;

    if (stats && stats.updatedAt > this.lastReindex) {
      this.teamsSort.reIndex();
      this.chaptersSort.reIndex();
      this.lastReindex = Date.now();
    }

    this.fetchStats();
  }

  fetchStats = async () => {
    const { isInitializing } = this.props;
    const { stats } = this.state;

    if (isInitializing || stats || this.fetchingStats) {
      return;
    }
  
    this.fetchingStats = true;

    try {
      const datas = await api.getStatistics();

      if (this.unmount) {
        return;
      }

      this.setState({
        stats: {
          ...datas,
          graph: {
            labels: datas.graph.map(l => l.month),
            datasets: [
              {
                label: "Registrations",
                data: datas.graph.map(l => l.registrations),
              }
            ]
          },
          updatedAt: Date.now()
        },
      });

    } catch (e) {}

    this.fetchingStats = false;
  }

  componentWillUnmount = () => {
    this.unmount = true;
  }

  render() {
    const { stats } = this.state;
  
    return (
      <>
        <CardsHeader name="Default" parentName="Dashboards" stats={stats} />
        <Container className="mt--6" fluid>
          <Row>
            <Col>
              <Card className="bg-default">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-light text-uppercase ls-1 mb-1">
                        Last months
                      </h6>
                      <h5 className="h3 text-white mb-0">User registrations</h5>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={(stats && stats.graph) || {}}
                      options={dashboardGraphOptions}
                      id="chart-sales-dark"
                      className="chart-canvas"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Teams</h3>
                    </div>

                  </Row>
                </CardHeader>
                <div className="table-responsive" ref="teams-list">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="name" scope="col">Name</th>
                        <th className="sort" data-sort="users" scope="col">Users</th>
                        <th className="sort" data-sort="completion" scope="col">Video Average</th>
                        <th className="sort" data-sort="quizz-success" scope="col">Quizz success</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {stats && stats.teams.map(team => (
                        <tr key={team.id}>
                          <th className="name" scope="row">{team.name}</th>
                          <td className="users">{team.users}</td>
                          <td className="completion">
                            <div className="d-flex align-items-center">
                              <span className="mr-2">{team.videoAverage ? `${team.videoAverage}%` : ''}</span>
                              <div>
                                {team.videoViewed ? (
                                  <Progress
                                    max="100"
                                    value={team.videoAverage}
                                    color="gradient-info"
                                  />
                                ): null}
                              </div>
                            </div>
                          </td>
                          <td className="quizz-success">
                            <div className="d-flex align-items-center">
                              <span className="mr-2">{team.quizzAverage ? `${team.quizzAverage}%` : ''}</span>
                              <div>
                                {team.quizzAverage ? (
                                  <Progress
                                    max="100"
                                    value={team.quizzAverage}
                                    color="gradient-info"
                                  />
                                ): null}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Chapters</h3>
                    </div>
                  </Row>
                </CardHeader>
                <div className="table-responsive" ref="chapters-list">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="name" scope="col">Name</th>
                        <th className="sort" data-sort="users" scope="col">Watched</th>
                        <th className="sort" data-sort="completion" scope="col">Video average</th>
                        <th className="sort" data-sort="quizz-success" scope="col">Quizz success</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {stats && stats.chapters.map(chapter => (
                        <tr key={chapter.number}>
                          <th className="name" scope="row">{chapter.number}. {chapter.name}</th>
                          <td className="users">{chapter.videoViewed}</td>
                          <td className="completion">
                            <div className="d-flex align-items-center">
                              <span className="mr-2">{chapter.videoAverage ? `${chapter.videoAverage}%` : ''}</span>
                              <div>
                                {chapter.videoViewed ? (
                                  <Progress
                                    max="100"
                                    value={chapter.videoAverage}
                                    color="gradient-info"
                                  />
                                ): null}
                              </div>
                            </div>
                          </td>
                          <td className="quizz-success">
                            {chapter.quizzAverage ? (
                              <div className="d-flex align-items-center">
                                <span className="mr-2">{chapter.quizzAverage}%</span>
                                <div>
                                  <Progress
                                    max="100"
                                    value={chapter.quizzAverage}
                                    color="gradient-info"
                                  />
                                </div>
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Dashboard;
