import React from "react";
import moment from "moment";
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import NewMemberModal from './NewMemberModal';

import { api } from '../../../services';

class Members extends React.Component {
  state = {
    showCreateModal: false,
    showDeleteWarning: false,
    showDeleteConfirmation: false,
    deleteMember: null,
    modalContent: null,
    members: null,
  }

  fetchingApi = false;

  componentDidMount() {
    this.fetchDatas();
  }

  componentDidUpdate = () => {
    this.fetchDatas();
  }

  fetchDatas = async (forceRefresh) => {
    const { isInitializing } = this.props;
    const { members } = this.state;

    if (isInitializing || (members && !forceRefresh) || this.fetchingApi) {
      return;
    }
  
    this.fetchingApi = true;
    try {
      const datas = await api.getMembers();
      this.setState({
        members: datas,
      });

    } catch (e) {}

    this.fetchingApi = false;
  }

  closeModal = (saved) => {
    if (saved) {
      this.fetchDatas(true);
    }
  
    this.setState({
      showCreateModal: false,
    });
  }

  showCreate = (e) => {
    e.preventDefault();
    this.setState({ showCreateModal: true, modalContent: null });
  }

  showUpdate = (content) => {
    this.setState({ showCreateModal: true, modalContent: content });
  }

  deleteMember = (content) => {
    this.setState({ showDeleteWarning: true, deleteMember: content });
  }

  hideAlert = () => {
    this.setState({
      showDeleteWarning: false,
      showDeleteConfirmation: false,
    });
  }

  doDeleteMember = async () => {
    const { deleteMember } = this.state;
    
    try {
      await api.deleteMember(deleteMember.id);
    } catch (e) {}

    this.fetchDatas(true);

    this.setState({
      showDeleteWarning: false,
      showDeleteConfirmation: true,
    });
  }

  render() {
    const {
      showDeleteWarning,
      showDeleteConfirmation,
      members
    } = this.state;

    return (
      <>
        {showDeleteWarning ? (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={this.doDeleteMember}
            onCancel={this.hideAlert}
            showCancel
            confirmBtnBsStyle="danger"
            confirmBtnText="Yes, delete it!"
            cancelBtnBsStyle="default"
            cancelBtnText="Cancel"
            btnSize=""
          >
            Do you really want to delete this member ?
          </ReactBSAlert>
        ) : null}
        {showDeleteConfirmation ? (
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Deleted!"
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
            confirmBtnBsStyle="primary"
            confirmBtnText="Ok"
            btnSize=""
          >
            This member has been deleted.
          </ReactBSAlert>
        ) : null}
        <NewMemberModal
          show={this.state.showCreateModal}
          content={this.state.modalContent}
          onClose={this.closeModal}
        />
        <SimpleHeader name="Members" />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Members</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    href="#pablo"
                    id={`addMember`}
                    onClick={this.showCreate}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-fat-add" />
                    </span>
                    <span>Add member</span>
                  </Button>
                  <UncontrolledTooltip delay={0} target={`addMember`}>
                    Add a new member
                  </UncontrolledTooltip>
                </Col>
              </Row>
            </CardHeader>

            <Table
              className="align-items-center table-flush"
              responsive
              striped
            >
              <thead className="thead-light">
                <tr>
                  <th>Name</th>
                  <th>email</th>
                  <th>Created at</th>
                  <th className="fit" />
                </tr>
              </thead>
              <tbody>
                {members && members.map(member => (
                  <tr key={member.id}>
                    <td className="table-user">
                      <b>{member.name}</b>
                    </td>
                    <td>{member.email}</td>
                    <td>
                      <span className="text-muted">{moment(member.created_at).format('LL')}</span>
                    </td>
                    <td className="table-actions fit">
                      <a
                        className="table-action"
                        href={`#${member.id}`}
                        id={`memberUpdate-${member.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.showUpdate(member);
                        }}
                      >
                        <i className="fas fa-edit" />
                      </a>
                      <UncontrolledTooltip delay={0} target={`memberUpdate-${member.id}`}>
                        Edit member
                      </UncontrolledTooltip>
                      <a
                        className="table-action table-action-delete"
                        href={`#${member.id}`}
                        id={`memberDelete-${member.id}`}
                        onClick={e => {
                          e.preventDefault();
                          this.deleteMember(member);
                        }}
                      >
                        <i className="fas fa-trash" />
                      </a>
                      <UncontrolledTooltip delay={0} target={`memberDelete-${member.id}`}>
                        Delete member
                      </UncontrolledTooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Container>
      </>
    );
  }
}

export default Members;
